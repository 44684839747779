import { defineAction } from '_utils/redux'
import * as providerService from '_services/provider'
import { notEmptyPayload, parseNumber, formatDateDays } from '_utils/helpers'
import { PROVIDER_FIELDS } from '_views/provider/constants'

export const GET_PROVIDERS = defineAction('GET_PROVIDERS')
export const GET_PROVIDER = defineAction('GET_PROVIDER')
export const GET_NEXT_PAGE_PROVIDERS = defineAction('GET_NEXT_PAGE_PROVIDERS')
export const UPDATE_PROVIDER = defineAction('UPDATE_PROVIDER')
export const GET_PROVIDERS_FILTERED = defineAction('GET_PROVIDERS_FILTERED')
export const GET_PROVIDERS_WITH_NO_PAGINATION = defineAction('GET_PROVIDERS_WITH_NO_PAGINATION')
export const CREATE_PROVIDER = defineAction('CREATE_PROVIDER')
export const DELETE_PROVIDER_ATTACHMENT = defineAction('DELETE_PROVIDER_ATTACHMENT')
export const ADD_PROVIDER_STAFF_USER = defineAction('ADD_PROVIDER_STAFF_USER')
export const DELETE_PROVIDER_STAFF_USER = defineAction('DELETE_PROVIDER_STAFF_USER')
export const UPDATE_PROVIDER_STAFF_USER = defineAction('UPDATE_PROVIDER_STAFF_USER')
export const SET_TABLE_PAGE = 'SET_TABLE_PAGE'
export const SET_FILTER = 'SET_FILTER'
export const CREATE_PROVIDER_CONTRACT = defineAction('CREATE_PROVIDER_CONTRACT')
export const DELETE_PROVIDER_CONTRACT = defineAction('DELETE_PROVIDER_CONTRACT')
export const GET_CONNECTION_MESSAGE_TOOL = defineAction('GET_CONNECTION_MESSAGE_TOOL')
export const GET_SUGGESTIONS_PROVIDERS = defineAction('GET_SUGGESTIONS_PROVIDERS')
export const VALIDATE_CPF_CNPJ = defineAction('VALIDATE_CPF_CNPJ')

export const GET_PROVIDERS_ORDERS = defineAction('GET_PROVIDERS_ORDERS')
export const GET_LEAD_PROVIDERS = defineAction('GET_LEAD_PROVIDERS')
export const IMPORT_LEAD_PROVIDERS = defineAction('IMPORT_LEAD_PROVIDERS')

const formatPayload = values => {
  const payload = notEmptyPayload(values)

  if (payload[PROVIDER_FIELDS.PHONE]) {
    payload[PROVIDER_FIELDS.PHONE] = parseNumber(payload[PROVIDER_FIELDS.PHONE])
  }

  if (payload[PROVIDER_FIELDS.CNPJ]) {
    payload[PROVIDER_FIELDS.CNPJ] = parseNumber(payload[PROVIDER_FIELDS.CNPJ])
  }

  if (payload[PROVIDER_FIELDS.BANK_ACCOUNT_CPF_CNPJ]) {
    payload[PROVIDER_FIELDS.BANK_ACCOUNT_CPF_CNPJ] = parseNumber(
      payload[PROVIDER_FIELDS.BANK_ACCOUNT_CPF_CNPJ]
    )
  }

  if (payload[PROVIDER_FIELDS.COMPANY_CREATION_DATE]) {
    payload[PROVIDER_FIELDS.COMPANY_CREATION_DATE] = formatDateDays(
      payload[PROVIDER_FIELDS.COMPANY_CREATION_DATE],
      'dd/MM/yyyy',
      'yyyy-MM-dd'
    )
  }

  if (payload[PROVIDER_FIELDS.MAIN_SERVICES]) {
    const formattedService = formatMainService(payload[PROVIDER_FIELDS.MAIN_SERVICES])
    return {
      ...payload,
      mainServices: formattedService?.mainServices,
      mainServiceTypes: formattedService?.mainServiceTypes,
    }
  }
  return payload
}

const formatMainService = items => {
  return items.reduce(
    (acc, item) => ({
      mainServices: [...acc.mainServices, item?.category],
      mainServiceTypes: [...acc.mainServiceTypes, item?.category],
    }),
    { mainServices: [], mainServiceTypes: [] }
  )
}

export const getProviders = params => (dispatch, getState) => {
  const queryParams = {
    pageSize: params?.pageSize || getState().providers.size,
  }
  dispatch({
    type: GET_PROVIDERS.ACTION,
    payload: providerService.getProviders(getState().user.authToken)(queryParams),
    meta: queryParams,
  })
}

export const getProvider = providerId => async (dispatch, getState) => {
  const data = await providerService.getProvider(getState().user.authToken)(providerId)
  dispatch({
    type: GET_PROVIDER.ACTION,
    payload: async () => data,
  })
}

export const getProvidersNextPage = params => (dispatch, getState) => {
  const queryParams = {
    pageSize: params?.pageSize || getState().providers.size,
    page: params.page,
    ...params.filters,
  }

  dispatch({
    type: GET_NEXT_PAGE_PROVIDERS.ACTION,
    payload: providerService.getProviders(getState().user.authToken)(queryParams),
  })
}

export const getProvidersFiltered =
  (filters = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: GET_PROVIDERS_FILTERED.ACTION,
      payload: providerService.getProviders(getState().user.authToken)({
        ...getState().providers.filter.toJS(),
        ...filters,
      }),
    })
  }

export const getProvidersWithoutPagination =
  (filters = {}) =>
  async (dispatch, getState) => {
    const data = await providerService.getProviders(getState().user.authToken)({
      ...getState().providers.filter.toJS(),
      ...filters,
      pageSize: 'none',
    })
    dispatch({
      type: GET_PROVIDERS_WITH_NO_PAGINATION.ACTION,
      payload: async () => data,
    })
    return data
  }

export const setTablePage = page => dispatch =>
  dispatch({
    type: SET_TABLE_PAGE,
    payload: page,
  })

export const updateProvider = payload => (dispatch, getState) => {
  const providerId = getState().providers.currentProvider

  // const newPayload = { ...payload }
  dispatch({
    type: UPDATE_PROVIDER.ACTION,
    payload: providerService.updateProvider(getState().user.authToken)(
      providerId,
      formatPayload(payload)
    ),
  })
}

export const deleteProviderAttachment = attachmentId => (dispatch, getState) => {
  const providerId = getState().providers.currentProvider

  dispatch({
    type: DELETE_PROVIDER_ATTACHMENT.ACTION,
    payload: providerService.deleteProviderAttachment(getState().user.authToken)(
      providerId,
      attachmentId
    ),
    meta: { attachmentId, providerId },
  })
}

export const deleteProviderContract = attachmentId => (dispatch, getState) => {
  const providerId = getState().providers.currentProvider

  dispatch({
    type: DELETE_PROVIDER_CONTRACT.ACTION,
    payload: providerService.deleteProviderContract(getState().user.authToken)(
      providerId,
      attachmentId
    ),
    meta: { attachmentId, providerId },
  })
}

export const createProviderContract = payload => (dispatch, getState) => {
  const providerId = getState().providers.currentProvider
  const data = providerService.createProviderContract(getState().user.authToken)(
    providerId,
    payload
  )
  dispatch({
    type: CREATE_PROVIDER_CONTRACT.ACTION,
    payload: data,
  })
  return data
}

export const setFilter = param => dispatch =>
  dispatch({
    type: SET_FILTER,
    payload: param,
  })

export const createProvider = payload => (dispatch, getState) => {
  dispatch({
    type: CREATE_PROVIDER.ACTION,
    payload: providerService.createProvider(getState().user.authToken)(formatPayload(payload)),
  })
}

export const addProviderStaffUser = payload => (dispatch, getState) => {
  const providerId = getState().providers.currentProvider
  dispatch({
    type: ADD_PROVIDER_STAFF_USER.ACTION,
    payload: providerService.addProviderStaffUser(getState().user.authToken)(providerId, payload),
    meta: { providerId },
  })
}

export const deleteProviderStaffUser = staffId => (dispatch, getState) => {
  const providerId = getState().providers.currentProvider
  dispatch({
    type: DELETE_PROVIDER_STAFF_USER.ACTION,
    payload: providerService.deleteProviderStaffUser(getState().user.authToken)(
      providerId,
      staffId
    ),
    meta: { providerId },
  })
}

export const updateProviderStaffUser = payload => (dispatch, getState) => {
  const providerId = getState().providers.currentProvider
  dispatch({
    type: UPDATE_PROVIDER_STAFF_USER.ACTION,
    payload: providerService.updateProviderStaffUser(getState().user.authToken)(
      providerId,
      payload,
      payload.id
    ),
    meta: { providerId },
  })
}

export const getConnetionMessageTool = params => (dispatch, getState) => {
  const data = providerService.getConnetionMessageTool(getState().user.authToken)(params)
  dispatch({
    type: GET_CONNECTION_MESSAGE_TOOL.ACTION,
    payload: data,
  })
  return data
}

export const getProviderSuggestionsList = payload => async (dispatch, getState) => {
  const data = await providerService.getProviderSuggestionsList(getState().user.authToken)(payload)
  dispatch({
    type: GET_SUGGESTIONS_PROVIDERS.ACTION,
    payload: async () => data,
  })
  return data
}

export const getProvidersOrders = params => async (dispatch, getState) => {
  const data = await providerService.getProviderOrders(getState().user.authToken)(params)

  dispatch({
    type: GET_PROVIDERS_ORDERS.ACTION,
    payload: async () => data,
    meta: params,
  })

  return data
}

export const getLeadProviders = params => async (dispatch, getState) => {
  const data = await providerService.getLeadProviders(getState().user.authToken)(params)

  dispatch({
    type: GET_LEAD_PROVIDERS.ACTION,
    payload: async () => data,
  })

  return data
}

export const importLeadProviders = payload => async (dispatch, getState) => {
  const data = await providerService.importLeadProviders(getState().user.authToken)(payload)

  dispatch({
    type: IMPORT_LEAD_PROVIDERS.ACTION,
    payload: async () => data,
  })

  return data
}

export const validateProviderCpfCnpj = params => async (dispatch, getState) => {
  const data = await providerService.validateProviderCpfCnpj(getState().user.authToken)(params)

  dispatch({
    type: VALIDATE_CPF_CNPJ.ACTION,

    payload: async () => data,
  })

  return data
}
